// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
	@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
	@import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
	@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}

.kt-header-menu-dark {
	@import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
	@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
	@import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
	@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
	@import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

// Primefaces themes
@import "../node_modules/primereact/resources/primereact.min.css";
@import "../node_modules/primereact/resources/themes/nova-light/theme.css";
@import "../node_modules/primeicons/primeicons.css";

@-webkit-keyframes fadeInUpContent {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -5%, 0);
		transform: translate3d(0, -5%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInUpContent {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -5%, 0);
		transform: translate3d(0, -5%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.fadeInUpContent {
	-webkit-animation-name: fadeInUpContent;
	animation-name: fadeInUpContent;
}

.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--active>.kt-menu__heading .kt-menu__link-icon,
.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--active>.kt-menu__link .kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__heading .kt-menu__link-icon,
.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__link .kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--open>.kt-menu__heading .kt-menu__link-icon,
.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--open>.kt-menu__link .kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot>span,
.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot>span {
	background-color: #607d8b;
}

.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active>.kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot>span,
.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active>.kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot>span {
	background-color: #607d8b;
}

.btn-primary {
	background-color: #147dbf !important;
	border-color: #147dbf !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
	background-color: #36a5eb !important;
	border-color: #36a5eb !important;
	color: #fff !important;
}

.btn-primary:disabled {
	background-color: #67666e !important;
	border-color: #67666e !important;
}

.MuiInput-underline:after {
	border-bottom: 2px solid #67666e !important;
}

.MuiFormLabel-root.Mui-focused {
	color: #67666e !important;
}

.margin-icon {
	margin-right: 8px;
}

.btn-table-action>i {
	width: 40px !important;
	font-size: 13px !important;
	padding: 0 !important;
}

.btn-table-action {
	margin: 0px 3px 0px 3px;
	height: 40px;
	width: 40px;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.form-group .btn-table-action {
	height: 51.25px;
	width: 51.25px;
}

.btn-table-action-small>i {
	width: 35px !important;
	font-size: 12px !important;
}

.btn-table-action-small {
	height: 30px;
	width: 30px;
	padding: 0 !important;
}

.p-datatable-emptymessage {
	height: 100px !important;
	text-align: center !important;
	font-size: 21px !important;
	font-weight: 600 !important;
}

.p-paginator-bottom {
	padding: 10px !important;
	background: #fff !important;
	border: 1px solid #f4f4f4 !important;
	border-bottom-right-radius: 8px !important;
	border-bottom-left-radius: 8px !important;
	text-align: right !important;
}

.p-paginator-current {
	color: #434349 !important;
}

.p-paginator-page.p-highlight {
	background-color: #147dbf !important;
}

.p-paginator-element.p-link {
	margin: 0px 2px 0px 2px !important;
	border-radius: 5px !important;
}

.p-button {
	margin: 0;
	background-color: #147dbf !important;
	border-radius: 0.25rem !important;
	border: none !important;
}

.p-datatable .p-datatable-thead>tr>th {
	text-align: left !important;
	background-color: #fff !important;
}

.p-datatable .p-datatable-thead>tr>th.text-center {
	text-align: center !important;
	background-color: #fff !important;
}

.p-datatable .p-datatable-thead>tr {
	height: 50px !important;
}

.p-datatable .p-datatable-tbody>tr>td {
	border: 1px solid #f4f4f4 !important;
}

.p-datatable .p-datatable-thead>tr>th {
	border: 1px solid #f4f4f4 !important;
}

.p-datatable-header {
	height: min-content;
	background-color: #fff !important;
	border: none !important;
	padding-right: 0 !important;
}

.p-datatable-loading-overlay.p-component-overlay {
	background-color: #fff !important;
}

.p-datatable-loading-content {
	top: 15% !important;
}

.p-inputtext {
	border: 1px solid #e2e1e1 !important;
}

label.p-dropdown-label.p-inputtext {
	border: none !important;
}

.MuiFormLabel-root.Mui-focused {
	color: #67666e !important;
}

.MuiInput-underline:focus:not(.Mui-focused):before,
.MuiInput-underline:active:not(.Mui-focused):before,
.MuiInput-underline:hover:not(.Mui-disabled):before {
	border-bottom: 1px solid #878691 !important;
}

.MuiInput-underline:after {
	border-bottom: 2px solid #878691 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #67666e !important;
}

.btn>i.fa-spin {
	padding-right: 0px !important;
	margin-right: 16px !important;
}

.kt-portlet {
	margin: 20px;

	&.agendamentos-custom {
		margin: 5px 0px 0px;
		padding: 0px 10px 0px;

		.kt-portlet__body {
			padding: 0px;
		}
	}
}

.kt-portlet__head-title {
	padding-top: 15px !important;
	padding-bottom: 15px !important;
}

.anexoBtnRemove {
	width: 100%;
	border-radius: 0px 0px 10px 10px;
}

.anexoBackground {
	height: 250px;
	width: 100%;
	border-radius: 10px 10px 0px 0px;
	background-size: cover !important;
}

.anexoContainer {
	margin-top: 15px;
	margin-bottom: 15px;
}

.kt-notification__item-icon>i {
	color: #147dbf !important;
}

body a:hover {
	color: #147dbf !important;
}

.kt-login__title {
	color: #424242 !important;
	font-weight: 600 !important;
}

.kt-login__subtitle {
	font-size: 1.3rem !important;
	color: #616161 !important;
}

.kt-login__copyright {
	color: #424242 !important;
}

.kt-login__menu>a {
	text-decoration: none !important;
	color: #424242 !important;
	text-shadow: 1px 1px 5px #fff !important;
}

.kt-login__menu>a:hover {
	text-decoration: none !important;
	color: #212121 !important;
}

.kt-scrolltop {
	background: #147dbf !important;
}

.btn-app-color {
	border-color: #147dbf !important;
	background-color: white !important;
	color: #147dbf !important;
}

.btn-app-color:hover {
	border-color: #147dbf !important;
	background-color: #147dbf !important;
	color: white !important;
}

.MuiInputBase-input.Mui-disabled {
	color: rgba(0, 0, 0, 0.65) !important;
}

.atalho-dashboard {
	background-color: #147dbf !important;
	color: white !important;
	height: 200px !important;
	font-weight: 600 !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.atalho-dashboard>i {
	font-size: 4.5rem !important;
	margin: 0px 0px 25px 0px !important;
}

.atalho-dashboard>h3 {
	font-size: 1.8rem !important;
	font-weight: 600 !important;
}

.no-content-dashboard {
	margin: 50px 60px 50px 60px;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.no-content-dashboard>i {
	font-size: 5rem !important;
	margin: 0px 0px 40px 0px !important;
}

.no-content-dashboard>h3 {
	font-size: 2.3rem !important;
	font-weight: 600 !important;
}

.no-content-dashboard>p {
	margin-top: 10px !important;
	font-size: 1.2rem !important;
	font-weight: 300 !important;
}

.item-atendimentos-hoje:not(:last-child) {
	margin-bottom: 2.3rem !important;
}

.item-atendimentos-hoje>.kt-timeline-v2__item-text {
	font-size: 1.2rem !important;
	padding: 0.2rem 0 0 5rem !important;
}

.nav-item.nav-link {
	padding: 15px 10px 15px 10px !important;
	color: #444 !important;
	border-radius: 0 !important;
}

.nav-item.nav-link:hover:not(.active) {
	color: #999 !important;
	border: 1px solid transparent !important;
}

.nav-item.nav-link.active {
	border-radius: 0px !important;
	border-top: 3px solid #147dbf !important;
	font-weight: 500 !important;
}

.nav-item.nav-link:first-child {
	border-left: 1px solid transparent !important;
}

.nav-tabs {
	border-bottom: 1px solid #ddd !important;
}

.tab-pane {
	padding: 0px 25px 25px 25px !important;
}

a.btn:hover {
	color: white !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
	color: #147dbf !important;
}

.MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
	background-color: rgba(20, 125, 191, 0.5) !important;
}

.MuiSwitch-thumb {
	box-shadow: none !important;
}

.col-btn-with-input {
	display: flex;
	align-items: flex-end;
	padding-bottom: 8px;
}

.col-btn {
	display: flex;
	align-items: flex-end;
}

.col-btn>.btn {
	margin-top: 16px;
	margin-bottom: 8px;
}

.btn-with-input {
	height: 52px;
}

.label-with-texfield {
	margin-bottom: 0px !important;
}

.tableHeaderItem {
	margin-top: 10px !important;
	margin-bottom: 10px !important;
}

.form-control:focus {
	border-color: #607d8b;
}

.btn.btn-label-brand {
	background-color: white !important;
	color: #147dbf !important;
}

.btn.btn-label-brand:hover,
.btn.btn-label-brand:focus {
	background-color: #147dbf !important;
	color: white !important;
}

.empty-icon {
	margin-top: 50px;
	font-size: 4.2rem;
}

.empty-text {
	margin: 50px 25px 50px 25px;
	font-size: 1.5rem;
}

.scroll::-webkit-scrollbar {
	width: 6px;
	height: 10px;
}

.scroll::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}

.scroll::-webkit-scrollbar-thumb {
	background: #808080;
	border: 0px none #ffffff;
}

.scroll::-webkit-scrollbar-thumb:hover {
	background: #808080;
}

.scroll::-webkit-scrollbar-thumb:active {
	background: #808080;
}

.scroll::-webkit-scrollbar-track {
	background: #ccc;
	border: 0px none #ffffff;
}

.scroll::-webkit-scrollbar-track:hover {
	background: #c0c0c0;
}

.scroll::-webkit-scrollbar-track:active {
	background: #c0c0c0;
}

.scroll::-webkit-scrollbar-corner {
	background: transparent;
}

.swal2-popup {
	border-radius: 15px !important;
}

.forgot-password,
.forgot-password:hover {
	color: rgba(0, 0, 0, 0.54);
}

.group-label {
	padding: 10px 15px;
	font-size: 11px;
	text-transform: uppercase;
}

// Start Custom CSS

.imagemUploaderBotaoRemover {
	width: 100%;
	border-radius: 0px 0px 10px 10px;
}

.imagemUploaderBackground {
	height: 250px;
	width: 100%;
	border-radius: 10px 10px 0px 0px;
	background-size: cover !important;

	&.logoUploaderCustomBackground {
		height: 256px;
	}
}

.imagemUploaderMargem {
	margin-top: 15px;
	margin-bottom: 15px;
}

.fileUploaderBackground {
	width: 100%;
	border-radius: 10px 10px 0px 0px;
	background-color: #cdcdcd;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.btn-filtros {
	margin-right: 5px;
}

body .fc th {
	padding: 0;
}

.eventWithPlan.cor-141414 {
	background-color: #141414;
	border-color: #141414;
	color: #FFF;

}

.eventWithPlan.cor-141414:not(.eventFinished) {
	.event-cliente-telefone {
		background-color: #363636;
		z-index: 2;
	}
}

.eventWithPlan.cor-141414:hover {
	background-color: #363636;
	border-color: #363636;
	color: #fff;
}

.eventWithPlan.cor-A54800 {
	background-color: #A54800;
	border-color: #A54800;
	color: #FFF;

	.event-cliente-telefone {
		background-color: #913F00;
		z-index: 2;
	}
}

.eventWithPlan.cor-A54800:hover {
	background-color: #913f00;
	border-color: #913f00;
	color: #fff;
}

.eventWithPlan.cor-09326C {
	background-color: #09326C;
	border-color: #09326C;
	color: #FFF;

	.event-cliente-telefone {
		background-color: #06244F;
		z-index: 2;
	}
}

.eventWithPlan.cor-09326C:hover {
	background-color: #06244f;
	border-color: #06244f;
	color: #fff;
}

.eventWithPlan.cor-6CC3E0 {
	background-color: #6cc3e0;
	border-color: #6cc3e0 !important;
	color: #000;

	.event-cliente-telefone {
		background-color: #78D9FA;
		z-index: 2;
	}
}

.eventWithPlan.cor-6CC3E0:hover {
	background-color: #78d9fa;
	border-color: #78d9fa;
	color: #000;
}

.eventWithPlan.cor-94C748 {
	background-color: #94c748;
	border-color: #94c748 !important;
	color: #000;

	.event-cliente-telefone {
		background-color: #B6F558;
		z-index: 2;
	}
}

.eventWithPlan.cor-94C748:hover {
	background-color: #B6F558;
	border-color: #B6F558;
	color: #000;
}

.eventWithPlan.cor-E774BB {
	background-color: #e774bb;
	border-color: #e774bb !important;
	color: #000;

	.event-cliente-telefone {
		background-color: #FA82CC;
		z-index: 2;
	}
}

.eventWithPlan.cor-E774BB:hover {
	background-color: #FA82CC;
	border-color: #FA82CC;
	color: #000;
}

.eventClientInadimplente {
	background-color: #5e4db2 !important;
	border-color: #5e4db2 !important;
}

.eventClientInadimplente:hover {
	background-color: #4b3aa3 !important;
	border-color: #4b3aa3 !important;
}

.eventFinished {
	background-color: #a9a9a9 !important;
	border-color: #a9a9a9 !important;
}

.eventWithPlan>.fc-daygrid-event-dot {
	background-color: #0abb87 !important;
	border-color: #0abb87 !important;
}

.event-cliente-telefone {
	position: absolute;
	width: auto;
	display: flex;
	font-size: 80%;
	top: 2px;
	right: 2px;
	visibility: hidden;
	padding-left: 4px;
	opacity: 0;
	transition: visibility 0s, opacity 0.2s linear;
}

.rbc-event:hover .event-cliente-telefone {
	visibility: visible;
	opacity: 1;
}

.alert-danger-div {
	margin: 20px;
}

.card-info {
	// border: 1px solid rgba(0, 0, 0, 0.35);
	border-radius: 4px;
	margin-bottom: 16px;
	padding: 20px;
	background-color: #e8e8e8;
}

.text-info {
	color: rgba(0, 0, 0, 0.7) !important;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
}

.fc-event {
	border: 1px solid #36a5eb !important;
}

.fc-day-number {
	color: #424242 !important;
}

.fc-toolbar>*> :not(:first-child) {
	margin-left: 0.4em !important;
}

.fc .fc-daygrid-day-frame {
	min-height: 124px !important;
}

.fc .fc-col-header-cell-cushion {
	color: rgb(31, 29, 29) !important;
}

.fc .fc-daygrid-day-number {
	color: rgb(31, 29, 29) !important;
}

.fc th .fc-scrollgrid-sync-inner {
	background-color: lightgrey !important;
}

.fc-button-primary {
	color: #36a5eb !important;
	background-color: white !important;
	border-color: #36a5eb !important;
	border-radius: 5px !important;
	height: 40px !important;
	padding-left: 15px !important;
	padding-right: 15px !important;
	margin: 5px !important;
}

.fc-button-primary:hover {
	color: white !important;
	background-color: #36a5eb !important;
	border-color: #36a5eb !important;
}

.fc-button-primary:not(:disabled):active,
.fc-button-primary:not(:disabled).fc-button-active {
	color: #fff !important;
	background-color: #36a5eb !important;
	border-color: #36a5eb !important;
}

.fc-button-primary:disabled {
	color: #fff !important;
	background-color: #116fbf !important;
	border-color: #116fbf !important;
}

.fc-center>h2 {
	color: #36a5eb !important;
}

.fc .fc-row {
	border-right: none !important;
}

.fc th {
	background-color: #fafafa !important;
	border: 1px solid #ddd !important;
	color: inherit !important;
}

a.fc-event:hover {
	color: white !important;
}

body .fc .fc-daygrid-event-harness .eventWithNoPlan {
	background-color: #36a5eb !important;
}

body .fc .fc-daygrid-event-harness .eventWithNoPlan:hover {
	background-color: #116fbf !important;
}

.input-comanda-total>div>fieldset {
	border-width: 2px;
	border-color: rgba(0, 0, 0, 0.65) !important;
}

.input-comanda-total>div>input {
	font-weight: 800;
}

.input-comanda-total>label {
	color: rgba(0, 0, 0, 0.65) !important;
	font-weight: 800;
}

.status_cliente_label {
	margin: 0;
	font-size: 1.2rem;
	font-weight: 500;
	color: #48465b;
	padding-top: 15px !important;
	padding-bottom: 15px !important;
}

.dropdown-menu {
	padding: 0.5rem;
}

.dialogListRow {
	margin: 32px;
	min-width: 400px;
}

.blur {
	filter: blur(1.5rem);
}

.modal-backdrop.show {
	opacity: 0.2 !important;
}

.modal-sm>.modal-content {
	width: 500px;
	margin: auto;
	border-radius: 8px;
}

.modal-auditoria {
	background: rgba(0, 0, 0, 0.4);
}

.auditoria-activities:not(:first-of-type) {
	margin-top: 16px;
}

.container-historico-caixas {
	max-width: 1600px;
}

.historico-caixas-list-header {
	background-color: white;
	padding: 0.75rem 1.25rem;
}

.card-historico-caixas-list {
	min-height: 5rem;
	padding-top: 1rem;
}

.list-group {
	max-height: 1140px;
	margin-bottom: 10px;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
}

.list-group>.list-group-item:not(:first-child) {
	border-top: 1px solid;
}

.flex-col-reverse {
	flex-direction: column-reverse;
}

.contained-button-primary {
	background: #0abb87 !important;
}

@media screen and (min-width: 768px) {
	.modal-dialog {
		max-width: 700px;
		margin: 1.75rem auto;
	}
}

@media screen and (max-width: 768px) {
	.dialogListRow {
		min-width: 0px !important;
	}
}

.infoTermoDeUso {
	color: #5a5f63;
	text-decoration-line: underline !important;

	.comanda-info-text {
		color: black;
	}

	// End Custom CSS

	@media screen and (max-width: 996px) {
		.kt-portlet {
			margin: 5px;
		}

		.alert-danger-div {
			margin: 5px !important;
		}

		.kt-portlet__body .btn {
			margin-bottom: 0px !important;
		}

		.div-buttons {
			display: flex;
			flex-direction: row-reverse;
			flex-wrap: wrap;
			width: 60%;
			gap: 8px;
			justify-content: space-between;
		}

		.div-buttons>button {
			width: 117px;
			margin-right: 0px !important;
		}
	}
}

.card-comandas-list {
	min-height: 2rem;
	padding-top: 0.25rem;
	display: grid;
}

.calendar-aside {
	background-color: white;
	padding: 16px;
	display: inline-block;
	margin-top: 5px;
	width: 100%;
	min-height: calc(100vh - 75px);

	.toggle-arrow {
		i {
			transition: transform 0.3s;
			transform: rotate(0, 0);
		}

		&.arrow-toggled {
			i {
				transform: scale(-1, -1);
			}
		}
	}
}

.toggle-arrow {
	text-align: center;
	margin-bottom: 32px;
	background-color: #1e1e2d;
}

.toggle-arrow:hover {
	cursor: pointer;
	background-color: white;
}

.toggle-arrow i {
	padding-right: 0px !important;
	color: white;
}

.toggle-arrow:hover i {
	color: #1e1e2d;
}

.btn-orange {
	background-color: #c86a41;
	color: #ffffff;
}

//Dpote CSS

.pote-header-barbeiros {
	display: flex;
	justify-content: space-between;
	font-weight: 700;
	margin: 16px;
}

.basic-text {
	font-weight: 500;
}

.pote-filial-block {
	display: inline-block;
	margin-top: 32px;
	margin-bottom: 32px;
}

.pote-info-block {
	display: inline-block;
}

.pote-span-block {
	display: block;
}

.result-step-body-buttons {
	margin-top: 32px;
	display: flex;
	align-items: center;
	flex-direction: row;
	gap: 24px;
	flex-wrap: wrap;
}

@media screen and (max-width: 1022px) {
	.calendar-aside {
		background-color: white;
		padding: 16px;
		display: inline-block;
		margin: 10px 0px;
		width: 100%;
		min-height: 0px !important;
	}
}

.menu-dpote,
.menu-clube {
	background: linear-gradient(0.25turn, rgba(45, 49, 161, 0.5), rgba(236, 50, 55, 0.5));
}

.menu-dpote>.kt-menu__link>.kt-menu__link-text,
.menu-clube>.kt-menu__link>.kt-menu__link-text {
	color: #fff !important;
}

.menu-dpote>.kt-menu__link>.kt-menu__link-icon,
.menu-clube>.kt-menu__link>.kt-menu__link-icon {
	color: #fff !important;
}

.menu-dpote>.kt-menu__link>.kt-menu__ver-arrow,
.menu-clube>.kt-menu__link>.kt-menu__ver-arrow {
	color: #fff !important;
}

.menu-pump {
	background: linear-gradient(0.25turn, #2d30a1, #ec3237);
}

.font-white {
	color: white;
}

.font-white:hover {
	font-weight: 900;
	color: white !important;
}

.menu-pump>.kt-menu__link>.kt-menu__link-text {
	color: #fff !important;
	font-size: 1.4rem !important;
	font-weight: 600 !important;
}

.menu-pump>.kt-menu__link>.kt-menu__link-icon {
	color: #fff !important;
	font-size: 1.5rem !important;
}

.menu-wrap span {
	text-wrap: wrap !important;
}

.carousel-indicators li {
	background-color: #5867dd;
}

.carousel-control-prev,
.carousel-control-next {
	width: 10%;
}

@media screen and (max-width: 1280px) {

	.p-datatable-responsive .p-datatable-thead>tr>th,
	.p-datatable-responsive .p-datatable-tfoot>tr>td {
		display: none !important;
	}

	.p-datatable-responsive .p-datatable-tbody>tr.p-datatable-row>td {
		text-align: left;
		display: block;
		border: 0 none;
		width: 100% !important;
		float: left;
		clear: left;
	}

	.p-datatable-responsive .p-datatable-tbody>tr.p-datatable-row>td .p-column-title {
		padding: .4em;
		min-width: 30%;
		display: inline-block;
		margin: -.4em 1em -.4em -.4em;
		font-weight: bold;
	}
}

.red-border-background {
	border: 2px solid rgb(253, 57, 122);
	background-color: rgba(254, 200, 218, 0.2) !important;
}

.btn-collapse-folgas {
	cursor: pointer;
	width: 100%;
	display: flex;
	justify-content: center;
	font-weight: 700;
	border: none;
	color: #646c9a;
}

.btn-collapse-folgas tr {
	font-weight: 100;
}

.sweet-alert-height-fixed {
	max-height: 100%;
	overflow-y: scroll !important;
}

.swal2-radio:has(label) {
	display: grid !important;
	text-align: left !important;

	label {
		cursor: pointer;
	}
}

.swal2-content:has(.swal2-radio) .swal2-validation-message {
	background-color: transparent !important;
}

.text-start {
	text-align: start;
}

.carousel-inner:has(.carousel-item-banner-empresa) {
	border-radius: 16px;
}

div.pseudo-option:has(.color-dot) {
	display: flex;
	align-items: center;
}

.color-dot {
	height: 16px;
	width: 16px;
	border-radius: 50%;
	margin-right: 8px;
}

@media only screen and (max-width: 425px) {
	.carousel-item-banner-empresa {
		height: 216px;
		width: 100%;
		object-fit: cover;
		border-radius: 16px;
	}
}

@media only screen and (min-width: 426px) and (max-width: 1024px) {
	.carousel-item-banner-empresa {
		height: 216px;
		width: 100%;
		object-fit: cover;
		border-radius: 16px;
	}
}

@media only screen and (max-width: 1024px) {
	.carousel-inner:has(.carousel-item-banner-empresa) {
		margin-left: 9px;
		margin-right: 9px;
		width: unset;
	}
}

@media only screen and (min-width: 1025px) {
	.carousel-item-banner-empresa {
		height: 180px;
		width: 100%;
		object-fit: cover;
		border-radius: 16px;
	}
}

.better-tooltip-text {
	font-size: 32px !important;
}

.simple-component-pagination {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 140px;
}

.simple-component-pagination * {
	margin: 0;
}

.simple-component-pagination i:not(.disabled) {
	color: #1b1c1f;
	cursor: pointer;
}

.simple-component-pagination i.disabled {
	color: #dbdbfb;
}

.simple-component-pagination i:not(:first-child):not(:last-child) {
	font-size: 16px;
}

.simple-component-pagination p {
	color: #fff;
	background-color: #3e3ec4;
	width: 32px;
	height: 28px;
	border-radius: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.accordion-toggle-versao {
	height: 40px;
	display: flex;
	align-items: center;
	padding: 8px !important;
	background-color: #fff !important;
	border-radius: 4px !important;
}

.accordion-versao {
	margin-bottom: 4px;
}

.auditoria-list-activities {
	margin: 16px 0;
}

.auditoria-list-activities:first-of-type {
	margin-top: 0px;
}

@media only screen and (min-width: 426px) {
	.accordion-versao {
		margin-left: 9px;
		margin-right: 9px;
	}

	@media only screen and (min-width: 1024px) {
		.accordion-versao {
			margin-left: -1px;
			margin-right: 9px;
		}
	}
}

@media only screen and (max-width: 425px) {
	.accordion-versao {
		margin-left: 1px;
		margin-right: 1px;
	}
}

.accordion-versao * {
	font-size: 16px;
	color: #53545c;
}

// Colorindo textos

.text-red {
	color: #ff3636;
}

.text-green {
	color: #30bf32;
}

.events-calendar-profissional {
	max-height: 100vh;
	overflow-y: scroll;
}

.fila-exibicao-agendamentos-chegou {
	background-color: #80e2a9;
}

.fila-exibicao-agendamentos-confirmado {
	background-color: #80e2a9;
}

.fila-exibicao-agendamentos-aguardando {
	background-color: #e2cd7b;
}

.fila-exibicao-agendamentos-faltou {
	background-color: #ec8074;
}

.fila-exibicao-agendamentos-day-text {
	font-size: 64px;
	font-weight: 700;
}

.fila-exibicao-agendamentos-legenda {
	font-weight: 500;
	font-size: 32px;
	display: flex;
	align-items: center;
	margin-right: 32px;
}

.fila-exibicao-agendamentos-legenda-circulo {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	margin-right: 10px;
}

.fila-exibicao-agendamentos-day-container {
	text-align: center;
}

@media only screen and (min-width: 376px) {
	.events-calendar-profissional .event-calendar-profissional {
		height: 132px !important;
	}
}

@media only screen and (max-width: 425px) {
	.events-calendar-profissional .event-calendar-profissional {
		height: 72px !important;
	}
}

.fade-out {
	animation: fadeOut 5s ease-in-out forwards !important;
	-webkit-animation: fadeOut 5s !important;
	-moz-animation: fadeOut 5s !important;
	-o-animation: fadeOut 5s !important;
	-ms-animation: fadeOut 5s !important;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.event-calendar-profissional {
	animation: fadeIn 5s;
	-webkit-animation: fadeIn 5s;
	-moz-animation: fadeIn 5s;
	-o-animation: fadeIn 5s;
	-ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.events-calendar-profissional .event-calendar-profissional {
	display: flex;
	cursor: pointer;
	border-radius: 4px;
	padding: 16px;
	margin-top: 16px;
}

.events-calendar-profissional .event-calendar-profissional p {
	margin-bottom: 4px;
}

.events-calendar-profissional .event-calendar-details {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.events-calendar-profissional .event-calendar-details>div {
	width: 100%;
}

.events-calendar-profissional .event-calendar-details .event-titulo {
	color: #000000;
	font-size: 24px;
	font-weight: 500;
}

.events-calendar-profissional .event-calendar-details .event-horario {
	color: #000000;
	font-size: 24px;
	font-weight: 400;
}

@media only screen and (max-width: 425px) {
	.events-calendar-profissional .event-calendar-details .event-horario {
		font-size: 14px;
	}

	.events-calendar-profissional .event-calendar-details .event-titulo {
		font-size: 14px;
	}
}

//Tela de fila de agendamentos
.barbeiro-name {
	font-size: 24px;
	font-weight: bold;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 200px;
	margin: auto;
}

.barbeiro-icon {
	border-radius: 8px;
	margin-bottom: 15px;
	margin: 8px auto 15px;
}

.title-fila-agendamentos {
	font-size: 32px;
	font-weight: bold;
}

.card-agendamento-fila-agendamentos {
	border-radius: 8px;
	width: 100%;
	background-color: #0b7a6c;
	border-color: #0b7a6c;
	margin-top: 8px;
	color: white;
	font-weight: 500;
	padding: 16px;
	font-size: 24px;
	font-family: "Inter", sans-serif;
	opacity: 0.9;
}

.card-agendamento-fila-agendamentos>.servico-name {
	font-size: 22px;
}

.card-agendamento-fila-agendamentos>p {
	margin-bottom: 0.5rem;
}

.card-agendamento-fila-agendamentos.bloqueado {
	background-color: rgb(231, 94, 94) !important;
}

.card-agendamento-fila-agendamentos.intervalo {
	background-color: #a9a9a9 !important;
}

.coluna-agendamentos-fila-agendamentos {
	height: calc(100% - 155px - 24px);
}

.coluna-agendamentos-fila-agendamentos>.card-agendamento-fila-agendamentos:first-child {
	margin-top: 16px !important;
}

.agendamento-with-plano {
	background-color: #946b12 !important;
	border-color: #946b12 !important;
}

.col.col-barbeiro-fila-agendamentos {
	padding-left: 5px;
	padding-right: 5px;
}

.row.row-barbeiro-fila-agendamentos {
	margin-left: 0px;
	margin-right: 0px;
}

.card-sem-agendamento-fila-agendamentos {
	color: white;
	border-radius: 8px;
	text-align: center;
	min-width: 104px;
	min-height: 104px;
	background-color: #e8c22a;
	padding: 16px;
	font-size: 24px;
	font-family: "Inter", sans-serif;
	font-weight: bold;
	margin-top: 8px;
}

.card-sem-agendamento-fila-agendamentos.agenda-vaga {
	height: 100%;
}

.coluna-agendamentos-fila-agendamentos>.card-sem-agendamento-fila-agendamentos:first-child {
	margin-top: 16px !important;
}

.whatsapp-float {
	position: fixed;
	width: 60px;
	height: 60px;
	bottom: 40px;
	right: 40px;
	background-color: #25d366;
	color: #fff;
	border-radius: 50px;
	text-align: center;
	font-size: 30px;
	box-shadow: 2px 2px 3px #999;
	z-index: 100;
}

.whatsapp-float:hover {
	text-decoration: none !important;
}

.whatsapp-float-icon {
	margin-top: 16px;
	color: white;
}

.table-horario-pico {
	border-radius: 6px;
	overflow: hidden;
	width: 100%;
	border-collapse: collapse;
	table-layout: fixed
}

.text-horario-pico {
	width: 51px;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: #777777;
	padding: 0px !important;
	padding-right: 24px !important;
}

.linhas-horario-pico {
	text-align: center;
	vertical-align: middle;
	border: 2px solid #ffffff;
	border-radius: 6px;
}

.horario-pico-80 {
	background-color: #D92222;
}

.horario-pico-60 {
	background-color: #FF8800;
}

.horario-pico-40 {
	background-color: #FFD740;
}

.horario-pico-20 {
	background-color: #77E542;
}

.horario-pico-0 {
	background-color: #21CFFF;
}

.horario-pico-indisponivel {
	background-color: #e8eaf2;
}

.horario-pico-indisponivel:hover {
	color: #777777 !important;
}

.horario-pico-dias {
	vertical-align: middle;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	text-align: center;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	padding-top: 16px;
	color: #777777;
}

.slot-horario {
	color: transparent;
	cursor: pointer;
	font-family: Inter;
	font-size: 18px;
	font-weight: 600;
	line-height: 27px;
	text-align: center;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	height: 41.25px;
}

.slot-horario:hover {
	color: #1B1C1F;
}

.horario-pico-60.slot-horario:hover,
.horario-pico-80.slot-horario:hover {
	color: #ffffff;
}

.kt-container:has(.table-horario-pico) {
	padding: 0 !important;

	.kt-portlet {
		margin: 24px;
		border-radius: 16px;
		box-shadow: none;
	}

	h3 {
		color: #3C3D43;
		text-align: center;
	}
}

.title-legenda-horario-pico {
	width: 100%;
	margin-bottom: 24px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.title,
	.subtitle {
		margin: 0;
		padding: 0;
	}

	.title {
		font-family: Inter;
		font-size: 20px;
		font-weight: 500;
		line-height: 30px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #3C3D43;
	}

	.subtitle {
		font-family: Inter;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #777777;
	}

	.legenda-container {
		display: flex;
		gap: 2px;
	}

	.legenda-item {
		font-family: Inter;
		font-size: 12px;
		font-weight: 400;
		line-height: 20px;
		text-align: center;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #777777;
		width: 80px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.legenda-0,
	.legenda-20,
	.legenda-40,
	.legenda-60,
	.legenda-80 {
		width: 100%;
		height: 16px;
		border-radius: 2px;
	}

	.legenda-0 {
		background-color: #21CFFF;
	}

	.legenda-20 {
		background-color: #77E542;
	}

	.legenda-40 {
		background-color: #FFD740;
	}

	.legenda-60 {
		background-color: #FF8800;
	}

	.legenda-80 {
		background-color: #D92222;
	}
}

.form-control-redesign {
	margin: 0 !important;
}

.form-control-redesign .MuiSelect-icon {
	color: #000000;
	font-size: 20px;
}

.form-control-redesign div,
.form-control-redesign div:focus {
	border-radius: 32px !important;
}

.filtros-horarios-pico {
	display: flex;
	align-items: center !important;

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: #3e3ec4 !important;
	}

	.form-control-redesign {
		height: 56px;
	}

	.col-btn-with-input {
		padding: 0;
		justify-content: center;
	}
}

.kt-portlet:has(.filtros-horarios-pico) {
	padding-top: 9px;
}

.horarioPico {
	font-family: 'Inter';

	.page-title {
		margin: 24px 0 24px 24px !important;
	}
}

.btn-accordion-relatorio-table {
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background-color: transparent;
	margin-bottom: 16px;
}

.regular-row {
	border: 2px solid #198754 !important;
	background-color: rgba(201, 254, 200, 0.2) !important;
	font-weight: 700 !important;
}

.regular-row.p-highlight {
	color: #000 !important;
}

.invalid-row {
	border: 2px solid #fd397a !important;
	background-color: rgba(254, 200, 218, 0.2) !important;
	font-weight: 700 !important;
}

.invalid-row.p-highlight {
	color: #000 !important;
}

.green-table-background {
	background-color: rgba(150, 253, 147, 0.2) !important;
}

.red-table-background {
	background-color: rgba(248, 151, 184, 0.2) !important;
}
.p-datatable .p-sortable-column.p-highlight {
	color: #000 !important;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
	color: #000 !important;
}
